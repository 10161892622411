import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';

import { AnalyticsProvider } from '~/ui/components/analytics/context';
import { client } from '~/v1/graphql/client';
import { useFavicon } from '~/v1/hooks/useFavicon';
import { BreadcrumbProvider } from '~/v1/system/breadcrumb/context';
import { ThemeManager } from '~/v1/system/theme/theme.manager';
import { type Metadata } from '~/v1/types/page';

import '../styles/index.scss';

const DEFAULT_METADATA: Metadata = {
  title: 'Mellon Foundation',
  description:
    'The Mellon Foundation makes grants to actively unlock the power in the arts and humanities that helps connect us all.',
} as const;

function getTitle(title?: Metadata['title']) {
  if (!title) return DEFAULT_METADATA.title;

  return `${title} | ${DEFAULT_METADATA.title}`;
}

function getDescription(description?: Metadata['description']) {
  if (!description) return DEFAULT_METADATA.description;

  const endsWithTerminator =
    description.endsWith('.') || description.endsWith('!') || description.endsWith('?');
  const descriptionWithTerminator = endsWithTerminator ? description : `${description}.`;
  return `${descriptionWithTerminator} ${DEFAULT_METADATA.description}`;
}

export default function App({ Component, pageProps, router }: AppProps) {
  const { theme, metadata, ...props } = pageProps;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.query]);

  const { favicon, imageData } = useFavicon(theme);

  const ogSearchParams = new URLSearchParams();
  ogSearchParams.set('path', router.asPath.split('?')[0]);
  if (theme) {
    ogSearchParams.set('primary', theme.primary);
    ogSearchParams.set('secondary', theme.secondary);
  }

  const title = getTitle(metadata?.title);
  const description = getDescription(metadata?.description);
  const ogTitle = metadata?.['og:title'] ?? metadata?.title ?? DEFAULT_METADATA.title;
  const ogDescription =
    metadata?.['og:description'] ?? metadata?.description ?? DEFAULT_METADATA.description;
  let ogImage = `/api/opengraph?${ogSearchParams}`;
  if (process.env.URL) {
    ogImage = `${process.env.URL}${ogImage}`;
  }

  if (metadata?.['og:image']) {
    ogImage = metadata['og:image'];
    if (ogImage.startsWith('//')) {
      ogImage = `https:${ogImage}`;
    }
  }

  return (
    <>
      <Head>
        {/* base metadata */}
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* opengraph */}
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@mellonfdn" />

        <link
          rel="icon"
          type="image/svg+xml"
          id="favicon"
          href={favicon ? `data:image/svg+xml;base64,${favicon}` : '/favicon.svg'}
        />
        <link
          rel="icon"
          type="image/x-icon"
          sizes="any"
          href={imageData ? imageData : '/favicon.ico'}
        />
        {imageData && (
          <>
            <link rel="icon" type="image/png" sizes="192x192" href={imageData} />
            <link rel="icon" type="image/png" sizes="512x512" href={imageData} />
            <link rel="apple-touch-icon" href={imageData} />
          </>
        )}
      </Head>

      <AnalyticsProvider>
        <ApolloProvider client={client}>
          {/* @ts-expect-error Theme is only undefined when generating sourcemaps */}
          <ThemeManager theme={theme}>
            <BreadcrumbProvider>
              <Component {...props} />
            </BreadcrumbProvider>
          </ThemeManager>
        </ApolloProvider>
      </AnalyticsProvider>
    </>
  );
}
