import { useEffect } from 'react';

import { type InitConfiguration } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';

import { type ClientUser } from '~/service/user/types';
import { context, environment } from '~/v1/constants/environment';

const applicationId = process.env.NEXT_PUBLIC_DD_APPLICATION_ID;
const clientToken = process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN;

if (context !== 'development') {
  if (!clientToken) {
    console.error('Missing DataDog client token');
  } else {
    // beforeSend is different between logs and rum
    const config: Omit<InitConfiguration, 'beforeSend'> = {
      clientToken,
      version: context === 'production' ? process.env.NEXT_PUBLIC_VERSION : process.env.BUILD_ID,
      env: environment,
      site: 'us5.datadoghq.com',
      service: 'mellonorg',
      sessionSampleRate: 100,
    };
    datadogLogs.init(config);

    if (!applicationId) {
      console.error('Missing DataDog application ID');
    } else {
      datadogRum.init({
        ...config,
        applicationId,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
      });

      datadogRum.setGlobalContextProperty('context', context);

      if (process.env.PULL_REQUEST === 'true') {
        datadogRum.setGlobalContextProperty('code', {
          pr: process.env.REVIEW_ID,
          branch: process.env.HEAD,
        });
      }
    }
  }
}

export interface DatadogProps {
  user: ClientUser | undefined;
}

export function Datadog({ user }: DatadogProps) {
  useEffect(() => {
    if (!user) {
      return;
    }
    // @ts-expect-error weird TS stuff. The Datadog User type and our ClientUser are absolutely compatible
    datadogRum.setUser(user);
  }, [user]);

  return null;
}
