import {
  ApolloClient,
  type DefaultOptions,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

export const GRAPHQL_PROXY = '/api/graphql';

const httpLink = createHttpLink({
  uri: process.env.SERVER_URI || GRAPHQL_PROXY,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': process.env.API_TOKEN,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors &&
    graphQLErrors.forEach(error => {
      throw error;
    });

  if (networkError) {
    throw networkError;
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const appLink = from([errorLink, authLink, httpLink]);

export const client = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache(),
  defaultOptions,
});
