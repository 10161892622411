import { useEffect } from 'react';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';

import { type ClientUser } from '~/service/user/types';

export interface GtmProps {
  user: ClientUser | undefined;
}

export function Gtm({ user }: GtmProps) {
  useEffect(() => {
    if (!user) {
      return;
    }
    sendGTMEvent([
      'set',
      {
        traffic_type: user.type,
      },
    ]);
  }, [user]);

  return (
    <GoogleTagManager
      gtmId="GTM-TS43CMS"
      // set default consent on init
      dataLayer={[
        'consent',
        'default',
        JSON.stringify({
          ad_storage: 'granted',
          analytics_storage: 'granted',
        }),
      ]}
    />
  );
}
