import { z } from 'zod';

export const BreadcrumbSchema = z.object({
  path: z.string(),
  label: z.string(),
});
export const BreadcrumbLabelsSchema = z.array(BreadcrumbSchema);
export const BreadcrumbStackSchema = z.array(z.number());
export const BreadcrumbEntriesSchema = z.record(BreadcrumbStackSchema);
export const CurrentBreadcrumbSchema = z.nullable(z.string());

export type Breadcrumb = z.infer<typeof BreadcrumbSchema>;
export type BreadcrumbStack = z.infer<typeof BreadcrumbStackSchema>;
export type CurrentBreadcrumb = z.infer<typeof CurrentBreadcrumbSchema>;

export function getValue<T>(key: string, schema: z.ZodType<T>, defaultValue: T): T {
  const value = sessionStorage.getItem(key);
  const jsonValue = value ? JSON.parse(value) : undefined;
  const parsedValue = schema.safeParse(jsonValue);
  if (!parsedValue.success) {
    setValue(key, defaultValue);
    return defaultValue;
  }
  return parsedValue.data;
}

export function setValue<T>(key: string, value: T) {
  sessionStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(new StorageEvent('storage', { key }));
}
