import { useState } from 'react';

import { useIsomorphicLayoutEffect } from 'framer-motion';

import { hexToHSL, hexToRGB } from '~/v1/system/theme/theme.utils';

import { ThemeContextProvider } from './theme.context';

export interface Theme {
  primary: string;
  secondary: string;
  copy: string;
  isFullScreenAnimation: boolean;
}

interface ThemeManagerProps {
  children: React.ReactNode;
  theme: Theme;
}

export const ThemeManager: React.FC<ThemeManagerProps> = ({ theme: rootTheme, children }) => {
  const [theme, setTheme] = useState<Theme>(rootTheme);

  useIsomorphicLayoutEffect(() => {
    document.documentElement.style.setProperty('--theme-primary', theme.primary);
    document.documentElement.style.setProperty('--theme-primary-rgb', hexToRGB(theme.primary));
    document.documentElement.style.setProperty('--theme-secondary', theme.secondary);
    document.documentElement.style.setProperty('--theme-copy', theme.copy);
    document.documentElement.style.setProperty('--theme-copy-rgb', hexToRGB(theme.copy));

    document.documentElement.style.setProperty(
      '--theme-primary-h',
      hexToHSL(theme.primary)[0].toString(),
    );
    document.documentElement.style.setProperty(
      '--theme-primary-s',
      hexToHSL(theme.primary)[1].toString() + '%',
    );
    document.documentElement.style.setProperty(
      '--theme-primary-l',
      hexToHSL(theme.primary)[2].toString() + '%',
    );

    let themeColorMeta = document.querySelector<HTMLMetaElement>('meta[name="theme-color"]');
    if (!themeColorMeta) {
      themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      document.head.appendChild(themeColorMeta);
    }
    themeColorMeta.setAttribute('content', theme.primary);
  }, [theme]);

  return (
    <ThemeContextProvider value={{ theme, rootTheme, setTheme }}>{children}</ThemeContextProvider>
  );
};
