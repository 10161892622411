import { createContext, useContext } from 'react';

import { type Theme } from './theme.manager';

export interface ThemeContext {
  theme: Theme;
  /* Root theme is set in _app and persisted even when `theme` changes */
  rootTheme: Theme;
  setTheme: (theme: Theme) => void;
}

export const ThemeContext = createContext<ThemeContext>({
  theme: {
    primary: '',
    secondary: '',
    copy: '',
    isFullScreenAnimation: true,
  },
  rootTheme: {
    primary: '',
    secondary: '',
    copy: '',
    isFullScreenAnimation: true,
  },
  setTheme: () => null,
});

export const ThemeContextProvider = ThemeContext.Provider;

/** returns the `theme` property of `ThemeContext` */
export function useThemeContext() {
  // for backwards compatibility, we don't want to return `setTheme` or `rootTheme` here
  return useContext(ThemeContext).theme;
}
