import { useCallback, useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum-slim';
import { isEqual } from 'lodash';
import sizeOf from 'object-sizeof';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import { StorageKey } from '~/v1/constants/storageKey';

import {
  type Breadcrumb,
  BreadcrumbEntriesSchema,
  BreadcrumbLabelsSchema,
  type BreadcrumbStack,
  type CurrentBreadcrumb,
  CurrentBreadcrumbSchema,
  getValue,
  setValue,
} from './utils';

// This is returned by getEntryIndex when the current path is the homepage.
// When this happens, we set the current breadcrumb to null to start a new stack on the next page.
export const IS_HOMEPAGE = Symbol('IS_HOMEPAGE');

const store = {
  subscribe: (onStoreChange: () => void) => {
    const onChange = (event: StorageEvent) => {
      if (
        event.key === StorageKey.BREADCRUMB_ENTRIES ||
        event.key === StorageKey.BREADCRUMB_LABELS ||
        event.key === StorageKey.BREADCRUMB_CURRENT
      ) {
        onStoreChange();
      }
    };

    window.addEventListener('storage', onChange);
    return () => window.removeEventListener('storage', onChange);
  },

  getSnapshot: () => ({
    entries: getValue(StorageKey.BREADCRUMB_ENTRIES, BreadcrumbEntriesSchema, {}),
    labels: getValue(StorageKey.BREADCRUMB_LABELS, BreadcrumbLabelsSchema, []),
    current: getValue(
      StorageKey.BREADCRUMB_CURRENT,
      CurrentBreadcrumbSchema,
      typeof window !== 'undefined' ? window.history.state?.key ?? '' : '',
    ),
  }),

  getServerSnapshot: () => ({
    entries: {},
    labels: [],
    current: null,
  }),
} as const;

export function useBreadcrumbStack() {
  const {
    entries,
    labels,
    current: currentCacheKey,
  } = useSyncExternalStoreWithSelector(
    store.subscribe,
    store.getSnapshot,
    store.getServerSnapshot,
    x => x,
    isEqual,
  );

  const current = entries[currentCacheKey] ?? [];

  const createNewStack = useCallback(
    (cacheKey: string, newStack: BreadcrumbStack) => {
      setValue(StorageKey.BREADCRUMB_ENTRIES, { ...entries, [cacheKey]: newStack });
      setValue(StorageKey.BREADCRUMB_CURRENT, cacheKey);
    },
    [entries],
  );

  const getEntryIndex = useCallback(
    (entry: Breadcrumb) => {
      if (entry.path === '/') {
        return IS_HOMEPAGE;
      }
      // we can assume "path" only ever shows up once in the labels array
      let index = labels.findIndex(({ path }) => path === entry.path);
      if (index === -1) {
        index = labels.length;
        setValue(StorageKey.BREADCRUMB_LABELS, [...labels, entry]);
      }
      return index;
    },
    [labels],
  );

  useEffect(() => {
    datadogRum.setGlobalContextProperty('breadcrumbs', {
      entries: {
        bytes: sizeOf(entries),
      },
      labels: {
        bytes: sizeOf(labels),
      },
    });
  }, [entries, labels]);

  return {
    entries,
    labels,
    current,
    setCurrent: (cacheKey: CurrentBreadcrumb) => setValue(StorageKey.BREADCRUMB_CURRENT, cacheKey),
    createNewStack,
    getEntryIndex,
  } as const;
}
