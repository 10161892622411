import { useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum-slim';
import { importSPKI, jwtVerify } from 'jose';

import { type ClientUser } from '~/service/user/types';
import { Datadog } from '~/ui/components/analytics/providers/datadog';
import { Gtm } from '~/ui/components/analytics/providers/gtm';

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<ClientUser>();

  useEffect(() => {
    const abortController = new AbortController();
    fetch('/api/user', { signal: abortController.signal })
      .then(response => response.text())
      .then(async signedUser => {
        const key = await importSPKI(process.env.NEXT_PUBLIC_USER_PUBLIC_KEY ?? '', 'RS256');
        const { payload } = await jwtVerify(signedUser, key);
        return payload as unknown as ClientUser;
      })
      .then(user => setUser(user))
      .catch(error => datadogRum.addError(error));
    return () => abortController.abort('');
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    // This is used by OptInMonster to target external users. Confirm with @emf before removing.
    sessionStorage.setItem('traffic', user.type);
  }, [user]);

  useEffect(() => {
    // Sep 5, 2024 - The old trafficType localstorage item is replaced by our user cookie. I'd
    //  prefer to not just leave it there, so we just remove it wherever it is set.
    localStorage.removeItem('mf_tr');
  }, []);

  return (
    // track event can be imported directly but is included in context for backwards compat
    <>
      <Datadog user={user} />
      <Gtm user={user} />
      {children}
    </>
  );
}
